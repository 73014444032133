<template>
  <div class="home flex flexC">
    <el-carousel height="502px" arrow="never" :interval=5000 >
      <el-carousel-item>
        <div class="carousel-contener">
          <div class="carousel-item-div" style="background-image: url(https://preview.aipage.com/resources/images/afb78379faf61586422159777.png);">
          </div>
          <div  class="box"  style="left:154px;top:100px;width:635px;min-height:200px;position:absolute;height:200px;">
            <div class="ele-wrap ">
              <div  class="text"  style="; left:9px;top:7px;width:567px;min-height:69px;position:absolute;height:69px;">
                <div>
                  <span style="font-size: 46px; color: rgb(255, 255, 255);">智慧点亮生活 科技成就未来</span>
                </div>
              </div>
              <div  class="text"  style="; left:13px;top:69px;width:163px;min-height:31px;position:absolute;height:31px;">
                <div>
                  <span style="color: rgb(255, 255, 255); font-size: 18px;">Technology and life</span>
                </div>
              </div>
              <div  class="line"  style="left:173px;top:74px;width:127px;min-height:20px;position:absolute;height:20px;">
                <div class="line line-h" style="border-color: #54edff; border-width: 3px; border-top-style: solid; ">
                </div>
              </div>
              <div  class="text"  style="; left:13px;top:100px;width:493px;min-height:31px;position:absolute;height:31px;">
                <div>
                  <span style="color: rgb(255, 255, 255); font-size: 20px;">Wisdom lights life, science and technology makes future</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="carousel-contener">
          <div class="carousel-item-div" style="background-image: url(https://preview.aipage.com/resources/images/afb78379faf61586421789376.png);">
          </div>
          <div  class="box"  style="left:154px;top:100px;width:635px;min-height:200px;position:absolute;height:200px;">
            <div class="ele-wrap ">
              <div  class="text"  style="; left:9px;top:7px;width:567px;min-height:69px;position:absolute;height:69px;">
                <div>
                  <span style="font-size: 46px; color: rgb(255, 255, 255);">智慧点亮生活 科技成就未来</span>
                </div>
              </div>
              <div  class="text"  style="; left:13px;top:69px;width:163px;min-height:31px;position:absolute;height:31px;">
                <div>
                  <span style="color: rgb(255, 255, 255); font-size: 18px;">Technology and life</span>
                </div>
              </div>
              <div  class="line"  style="left:173px;top:74px;width:127px;min-height:20px;position:absolute;height:20px;">
                <div class="line line-h" style="border-color: #54edff; border-width: 3px; border-top-style: solid; ">
                </div>
              </div>
              <div  class="text"  style="; left:13px;top:100px;width:493px;min-height:31px;position:absolute;height:31px;">
                <div>
                  <span style="color: rgb(255, 255, 255); font-size: 20px;">Wisdom lights life, science and technology makes future</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img src="" />
      </el-carousel-item>
    </el-carousel>

    <div class="m20 p20 tc">
      <p class="fb f24 mb20 pt20">选择我们的理由</p>
      <p class="f16">一直向着“做业内一流的互联网设计团队”这一愿景努力，致力于不断提升对网站高端设计，微信公众号/小程序开发等产品的用户体验</p>
    </div>
    <!-- <img alt="定制开发，一对一服务" class="w-full" src="../assets/banner1.png"> -->
    <div class="flex bgGray p20 mt1 dingzhikaifa">
      <div class="flex1 tc bgWhite m20 p20 br10 shadow5">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/svg/yingxiaowangzhan.svg">
            </div>
            <p class="title m10">网站开发</p>
            <div class="f12">全力打造的网站建设项目，定制化开发，多场景应用，满足不同的客户需求。</div>
      </div>
      <div class="flex1 tc bgWhite m20 p20 br10 shadow5">
            <img alt="定制开发，一对一服务" src="../assets/svg/APPkaifa.svg">
            <p class="title m10">APP开发</p>
            <div class="f12">全行业APP开发解决方案，实现快速搭建，满足APP跨多平台需求。</div>
      </div>
      <div class="flex1 tc bgWhite m20 p20 br10 shadow5">
            <img alt="定制开发，一对一服务" src="../assets/svg/weixin.svg">
            <p class="title m10">微信开发</p>
            <div class="f12">公众号、服务号、商城、H5开发，移动品牌传播，打造微信服务矩阵。</div>
      </div>
      <div class="flex1 tc bgWhite m20 p20 br10 shadow5">
            <img alt="定制开发，一对一服务" src="../assets/svg/xiaochengxu.svg">
            <p class="title m10">小程序开发</p>
            <div class="f12">各行业小程序定制开发，应用“触手可及”，打造微信产品和小程序矩阵。</div>
      </div>
      <div class="flex1 tc bgWhite m20 p20 br10 shadow5">
            <img alt="定制开发，一对一服务" src="../assets/svg/dianshang.svg">
            <p class="title m10">全平台开发</p>
            <div class="f12">全平台软件定制开发，系统定制化咨询、开发、维护等服务，实现项目快速落地。</div>
      </div>
    </div>

    <div class="m20 p20 tc">
      <p class="fb f24 mb20 pt20">我们承诺</p>
      <p class="f16">设计好、费用低、可落地、有保障</p>
    </div>
    <div class="flex bgGray p20">
      <div class="flex1 tc m10 p20">
            <div>
              <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic6.png">
            </div>
            <p class="title m10">网站开发</p>
            <div class="f12">全力打造的网站建设项目，定制化开发，多场景应用，满足不同的客户需求。</div>
      </div>
      <div class="flex1 tc m10 p20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic8.png">
            <p class="title m10">APP开发</p>
            <div class="f12">全行业APP开发解决方案，实现快速搭建，满足APP跨多平台需求。</div>
      </div>
      <div class="flex1 tc m10 p20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic7.png">
            <p class="title m10">微信开发</p>
            <div class="f12">公众号、服务号、商城、H5开发，移动品牌传播，打造微信服务矩阵。</div>
      </div>
      <div class="flex1 tc m10 p20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic9.png">
            <p class="title m10">小程序开发</p>
            <div class="f12">各行业小程序定制开发，应用“触手可及”，打造微信产品和小程序矩阵。</div>
      </div>
      <div class="flex1 tc m10 p20">
            <img alt="定制开发，一对一服务" class="br10 shadow5" src="../assets/pic10.png">
            <p class="title m10">全平台开发</p>
            <div class="f12">全平台软件定制开发，系统定制化咨询、开发、维护等服务，实现项目快速落地。</div>
      </div>
    </div>

    <div class="mt20 ml20 mr20 p20 tc">
      <p class="fb f24 mb20 pt20">服务过程</p>
      <p class="f16">全程管家式优质服务</p>
    </div>
    <div class="flex p20 mb20  work-flow">
      <div class="flex1 tc">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/pic11.png">
            </div>
            <p class="title line1">需求沟通</p>
            <div class="f12 pl20 pr20 ml20 mr20">面对面沟通网站需求和价值，确定网站定位</div>
      </div>
      <div class="flex1 tc">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/pic12.png">
            </div>
            <p class="title line1">需求调研</p>
            <div class="f12 pl20 pr20 ml20 mr20">分析行业特点，提供网站整体思路和创意设计</div>
      </div>
      <div class="flex1 tc">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/pic13.png">
            </div>
            <p class="title line1">定制设计</p>
            <div class="f12 pl20 pr20 ml20 mr20">专属定制符合企业品牌并突出企业文化的网站</div>
      </div>
      <div class="flex1 tc">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/pic14.png">
            </div>
            <p class="title line1">程序开发</p>
            <div class="f12 pl20 pr20 ml20 mr20">专业程序员代码编写完整还原方案设计</div>
      </div>
      <div class="flex1 tc">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/pic15.png">
            </div>
            <p class="title line1 line2">测试发布</p>
            <div class="f12 pl20 pr20 ml20 mr20">进行功能测试和漏洞修复，客户确认无问题后发布</div>
      </div>
      <div class="flex1 tc">
            <div>
              <img alt="定制开发，一对一服务" src="../assets/pic16.png">
            </div>
            <p class="title line1">方案策划</p>
            <div class="f12 pl20 pr20 ml20 mr20">确定网站模块功能充分展现企业形象</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Carousel, CarouselItem } from 'element-ui';
export default {
  name: 'Home',
  components: {
    ElCarousel: Carousel,
    ElCarouselItem: CarouselItem,
  },

}
</script>

<style>

.carousel-contener{
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel-item-div{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  display: flex;
  width: 100%;
  height: 100%;
}

@keyframes uk-scale-kenburns {
    0% {
      transform: scale(1.1)
    }
    100% {
      transform: scale(1.5)
    }
}

.is-active .carousel-item-div {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s;
  transform-origin: 0 50%;
  animation-direction: reverse;
  animation-timing-function: ease-in;
  transform: scale(1.1);
}

.work-flow .flex1 div img {
  width: 116px;
}
.dingzhikaifa div img {
  width: 80px;
}
</style>
